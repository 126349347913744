// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../styleguide/components/Link/A.res.js";
import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as Api from "../../../api/Api.res.js";
import * as Link from "../../../styleguide/components/Link/Link.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as Table from "../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Spinner from "../../../styleguide/components/Spinner/Spinner.res.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Checkbox from "../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../styleguide/icons/IconMenu.res.js";
import * as TableRow from "../../../styleguide/components/Table/TableRow.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as TableBody from "../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../styleguide/components/Table/TableCell.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../../styleguide/icons/IconSorting.res.js";
import * as SearchField from "../../../styleguide/forms/SearchField/SearchField.res.js";
import * as TableHeader from "../../../styleguide/components/Table/TableHeader.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as Routes_Resource from "../../../routes/common/Routes_Resource.res.js";
import * as TableHeaderCell from "../../../styleguide/components/Table/TableHeaderCell.res.js";
import * as DashboardIndexCss from "../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as DashboardTableCss from "../../../styleguide/dashboard/components/table/DashboardTableCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardFilterCss from "../../../styleguide/dashboard/components/filters/DashboardFilterCss.res.js";
import * as ResourceDashboardNavbar from "../resource-dashboard-navbar/ResourceDashboardNavbar.res.js";
import * as ResourcesAnalyticsOptions from "./ResourcesAnalyticsOptions.res.js";

var initialState_sortBy = {
  NAME: "TotalViews",
  VAL: "Desc"
};

var initialState_categoryFilter = ID.$$Set.make();

var initialState = {
  status: "FetchingResources",
  currentPage: 1,
  totalPages: 1,
  sortBy: initialState_sortBy,
  search: "",
  categoryFilter: initialState_categoryFilter
};

function ResourcesAnalytics$default(props) {
  var categories = props.categories;
  var container = React.useRef(null);
  var searchResources = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchResources" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              var match = state.search;
                              var tmp = match === "" ? undefined : state.search;
                              $$Promise.wait(Api.fetchResourcesAnalytics(state.currentPage, tmp, state.sortBy, Belt_Set.toList(state.categoryFilter)), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedResourcesFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ResourcesAnalytics",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "ResourcesAnalytics.default"
                                          }, "ResourcesDashboard::FetchResources::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailResourcesFetch");
                                    }));
                            })
                        };
              case "FailResourcesFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedResourcesFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            categoryFilter: state.categoryFilter
                          }
                        };
              case "SortByTitle" :
                  var match = state.status;
                  if (typeof match !== "object" && match === "FetchingResources") {
                    return "NoUpdate";
                  }
                  var match$1 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingResources",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: typeof match$1 === "object" && match$1.NAME === "Title" && match$1.VAL !== "Desc" ? ({
                                  NAME: "Title",
                                  VAL: "Desc"
                                }) : ({
                                  NAME: "Title",
                                  VAL: "Asc"
                                }),
                            search: state.search,
                            categoryFilter: state.categoryFilter
                          },
                          _1: (function (param) {
                              param.dispatch("FetchResources");
                            })
                        };
              case "SortByCategory" :
                  var match$2 = state.status;
                  if (typeof match$2 !== "object" && match$2 === "FetchingResources") {
                    return "NoUpdate";
                  }
                  var match$3 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingResources",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: typeof match$3 === "object" && match$3.NAME === "Category" && match$3.VAL !== "Desc" ? ({
                                  NAME: "Category",
                                  VAL: "Desc"
                                }) : ({
                                  NAME: "Category",
                                  VAL: "Asc"
                                }),
                            search: state.search,
                            categoryFilter: state.categoryFilter
                          },
                          _1: (function (param) {
                              param.dispatch("FetchResources");
                            })
                        };
              case "SortByDownloads" :
                  var match$4 = state.status;
                  if (typeof match$4 !== "object" && match$4 === "FetchingResources") {
                    return "NoUpdate";
                  }
                  var match$5 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingResources",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: typeof match$5 === "object" && match$5.NAME === "Downloads" && match$5.VAL !== "Desc" ? ({
                                  NAME: "Downloads",
                                  VAL: "Desc"
                                }) : ({
                                  NAME: "Downloads",
                                  VAL: "Asc"
                                }),
                            search: state.search,
                            categoryFilter: state.categoryFilter
                          },
                          _1: (function (param) {
                              param.dispatch("FetchResources");
                            })
                        };
              case "SortByTotalViews" :
                  var match$6 = state.status;
                  if (typeof match$6 !== "object" && match$6 === "FetchingResources") {
                    return "NoUpdate";
                  }
                  var match$7 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingResources",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: typeof match$7 === "object" && match$7.NAME === "TotalViews" && match$7.VAL !== "Desc" ? ({
                                  NAME: "TotalViews",
                                  VAL: "Desc"
                                }) : ({
                                  NAME: "TotalViews",
                                  VAL: "Asc"
                                }),
                            search: state.search,
                            categoryFilter: state.categoryFilter
                          },
                          _1: (function (param) {
                              param.dispatch("FetchResources");
                            })
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedResourcesFetch" :
                  var res = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res.resources
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            categoryFilter: state.categoryFilter
                          },
                          _1: (function (param) {
                              Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                      return prim.scrollTop;
                                    }));
                            })
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match$8 = state.status;
                  if (typeof match$8 !== "object" && match$8 === "FetchingResources") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingResources",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              categoryFilter: state.categoryFilter
                            },
                            _1: (function (param) {
                                param.dispatch("FetchResources");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0,
                            categoryFilter: state.categoryFilter
                          },
                          _1: searchResources
                        };
              case "PerformSearch" :
                  var match$9 = state.status;
                  if (typeof match$9 !== "object" && match$9 === "FetchingResources") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingResources",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              categoryFilter: state.categoryFilter
                            },
                            _1: (function (param) {
                                param.dispatch("FetchResources");
                              })
                          };
                  }
              case "ToggleCategoryFilter" :
                  var categoryId = action._0;
                  var match$10 = state.status;
                  if (typeof match$10 !== "object" && match$10 === "FetchingResources") {
                    return "NoUpdate";
                  }
                  var categoryFilter = Belt_Set.has(state.categoryFilter, categoryId) ? Belt_Set.remove(state.categoryFilter, categoryId) : Belt_Set.add(state.categoryFilter, categoryId);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingResources",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            categoryFilter: categoryFilter
                          },
                          _1: (function (param) {
                              param.dispatch("FetchResources");
                            })
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchResources");
        }), []);
  var len = categories.length;
  var tmp;
  if (len !== 1 && len !== 0) {
    var x = Belt_Set.size(state.categoryFilter);
    tmp = JsxRuntime.jsxs(Dropdown.make, {
          children: [
            JsxRuntime.jsx(Dropdown.Trigger.make, {
                  className: DashboardFilterCss.dropdown,
                  children: x !== 0 ? (
                      x !== 1 ? String(x) + " Categories" : "1 Category"
                    ) : "All Categories"
                }),
            JsxRuntime.jsx(Dropdown.Body.make, {
                  position: {
                    TAG: "Below",
                    _0: "LeftEdge"
                  },
                  className: DashboardFilterCss.dropdownBody,
                  children: Belt_Array.map(categories, (function (category) {
                          var id = "resource-categories-filter--category-" + ID.toString(category.id);
                          return JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Checkbox.make, {
                                              id: id,
                                              size: "SM",
                                              checked: Belt_Set.has(state.categoryFilter, category.id),
                                              onChange: (function (param) {
                                                  dispatch({
                                                        TAG: "ToggleCategoryFilter",
                                                        _0: category.id
                                                      });
                                                })
                                            }),
                                        JsxRuntime.jsx("label", {
                                              children: category.title,
                                              className: DashboardFilterCss.label,
                                              htmlFor: id
                                            })
                                      ],
                                      className: DashboardFilterCss.dropdownBodyRow
                                    }, id);
                        }))
                })
          ]
        });
  } else {
    tmp = null;
  }
  var match$1 = state.sortBy;
  var len$1 = categories.length;
  var tmp$1;
  if (len$1 !== 1 && len$1 !== 0) {
    var match$2 = state.sortBy;
    tmp$1 = JsxRuntime.jsx(Control.make, {
          onClick: (function (param) {
              dispatch("SortByCategory");
            }),
          children: JsxRuntime.jsxs("div", {
                children: [
                  "Category",
                  JsxRuntime.jsx(IconSorting.make, {
                        size: "XXS",
                        direction: typeof match$2 === "object" && match$2.NAME === "Category" ? match$2.VAL : undefined
                      })
                ],
                className: DashboardTableCss.controlCell
              })
        });
  } else {
    tmp$1 = null;
  }
  var match$3 = state.sortBy;
  var match$4 = state.sortBy;
  var resources = state.status;
  var tmp$2;
  tmp$2 = typeof resources !== "object" ? (
      resources === "FetchingResources" ? JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Spinner.make, {
                    size: "XL",
                    color: "Teal"
                  }),
              className: DashboardTableCss.spinner
            }) : JsxRuntime.jsx("div", {
              children: "Something went wrong during the search",
              className: DashboardTableCss.fetchError
            })
    ) : Belt_Array.map(resources._0, (function (resource) {
            var len = categories.length;
            return JsxRuntime.jsxs(TableRow.make, {
                        children: [
                          JsxRuntime.jsx(TableCell.make, {
                                children: JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(Link.make, {
                                            href: Routes_Resource.Dashboard.edit(resource.id),
                                            children: resource.title
                                          })
                                    })
                              }),
                          JsxRuntime.jsx(TableCell.make, {
                                children: len !== 1 && len !== 0 ? JsxRuntime.jsx("div", {
                                        children: resource.category
                                      }) : null
                              }),
                          JsxRuntime.jsx(TableCell.make, {
                                children: JsxRuntime.jsx("div", {
                                      children: String(resource.resourceDownloadsCount)
                                    })
                              }),
                          JsxRuntime.jsx(TableCell.make, {
                                children: JsxRuntime.jsx("div", {
                                      children: String(resource.ahoyEventsCount)
                                    })
                              }),
                          JsxRuntime.jsx(TableCell.make, {
                                children: JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsxs(Popover.make, {
                                            children: [
                                              JsxRuntime.jsx(Popover.Trigger.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          children: JsxRuntime.jsx(IconMenu.make, {
                                                                size: "MD",
                                                                color: "Teal"
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsxs(Dropdown.Body.make, {
                                                    position: {
                                                      TAG: "Below",
                                                      _0: "RightEdge"
                                                    },
                                                    className: DashboardFilterCss.dropdownBody,
                                                    children: [
                                                      JsxRuntime.jsx(Control.make, {
                                                            className: DashboardFilterCss.dropdownBodyRow,
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: Routes_Resource.Dashboard.edit(resource.id),
                                                                  className: DashboardFilterCss.dropdownBodyLink,
                                                                  children: "Edit"
                                                                })
                                                          }),
                                                      JsxRuntime.jsx(Control.make, {
                                                            className: DashboardFilterCss.dropdownBodyRow,
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: "/resources/" + (resource.categorySlug + ("/" + resource.slug)),
                                                                  className: DashboardFilterCss.dropdownBodyLink,
                                                                  children: "View"
                                                                })
                                                          })
                                                    ]
                                                  })
                                            ]
                                          })
                                    })
                              })
                        ]
                      }, ID.toString(resource.id));
          }));
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(ResourceDashboardNavbar.make, {
                      selectedCategory: "Analytics"
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: DashboardIndexCss.layout,
                      setRef: Caml_option.some(container),
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(H1.make, {
                                      className: DashboardIndexCss.title,
                                      children: "Resource Analytics"
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(SearchField.make, {
                                              id: "resources-search",
                                              value: state.search,
                                              placeholder: "Search resources",
                                              inputClassName: DashboardFilterCss.searchField,
                                              onChange: (function ($$event) {
                                                  dispatch({
                                                        TAG: "UpdateSearchInput",
                                                        _0: $$event.target.value
                                                      });
                                                })
                                            }),
                                        tmp,
                                        JsxRuntime.jsx(ResourcesAnalyticsOptions.make, {})
                                      ],
                                      className: DashboardIndexCss.headerControls
                                    })
                              ],
                              className: DashboardIndexCss.header
                            }),
                        JsxRuntime.jsx("section", {
                              children: JsxRuntime.jsxs(Table.make, {
                                    className: DashboardTableCss.table,
                                    children: [
                                      JsxRuntime.jsx(TableHeader.make, {
                                            children: JsxRuntime.jsxs(TableRow.make, {
                                                  children: [
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx(Control.make, {
                                                                onClick: (function (param) {
                                                                    dispatch("SortByTitle");
                                                                  }),
                                                                children: JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        "Resource",
                                                                        JsxRuntime.jsx(IconSorting.make, {
                                                                              size: "XXS",
                                                                              direction: typeof match$1 === "object" && match$1.NAME === "Title" ? match$1.VAL : undefined
                                                                            })
                                                                      ],
                                                                      className: DashboardTableCss.controlCell
                                                                    })
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: tmp$1
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx(Control.make, {
                                                                onClick: (function (param) {
                                                                    dispatch("SortByDownloads");
                                                                  }),
                                                                children: JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        "Downloads",
                                                                        JsxRuntime.jsx(IconSorting.make, {
                                                                              size: "XXS",
                                                                              direction: typeof match$3 === "object" && match$3.NAME === "Downloads" ? match$3.VAL : undefined
                                                                            })
                                                                      ],
                                                                      className: DashboardTableCss.controlCell
                                                                    })
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx(Control.make, {
                                                                onClick: (function (param) {
                                                                    dispatch("SortByTotalViews");
                                                                  }),
                                                                children: JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        "Page Views",
                                                                        JsxRuntime.jsx(IconSorting.make, {
                                                                              size: "XXS",
                                                                              direction: typeof match$4 === "object" && match$4.NAME === "TotalViews" ? match$4.VAL : undefined
                                                                            })
                                                                      ],
                                                                      className: DashboardTableCss.controlCell
                                                                    })
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: ""
                                                        })
                                                  ]
                                                })
                                          }),
                                      JsxRuntime.jsx(TableBody.make, {
                                            children: tmp$2
                                          })
                                    ]
                                  }),
                              className: DashboardTableCss.tableWrapper
                            }),
                        state.totalPages > 1 ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx(Pagination.make, {
                                      currentPage: state.currentPage,
                                      totalPages: state.totalPages,
                                      onPageClick: (function (x) {
                                          dispatch({
                                                TAG: "UpdatePage",
                                                _0: x
                                              });
                                        })
                                    }),
                                className: DashboardTableCss.pagination
                              }) : null
                      ]
                    })
              ]
            });
}

var Css;

var TableCss;

var Filters;

var $$default = ResourcesAnalytics$default;

export {
  Css ,
  TableCss ,
  Filters ,
  initialState ,
  $$default as default,
}
/* initialState Not a pure module */
